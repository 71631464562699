
import { Vue, Component, Ref } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { mdiPlus } from "@mdi/js";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { required } from "@/cms-services/consts";
import { Filter } from "@/cms-services/filter";
@Component({
  components: { DataEditorDialog },
})
export default class EventList extends Vue {
  @Ref("editor") editor!: DataEditorDialog;
  icons: any = {
    plus: mdiPlus,
  };

  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "Заголовок",
      value: "caption",
      sortable: true,
    },
    {
      text: "По умолчанию",
      value: "isDefault",
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  fields: any = [
    {
      editor: "string",
      attrs: {
        type: "text",
        outlined: true,
        label: "Заголовок",
      },
      name: "caption",
      validations: [required()],
    },
  ];

  eventObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.eventId,
    config: {},
    className: "event",
  });

  model: any = {
    eventId: +this.$route.params.eventId,
    caption: null,
  };

  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
      filter: [
        new Filter("eventId", +this.$route.params.eventId),
      ].toFilterString(),
    },
    className: "EventSchedule",
  });
  loaded: boolean = false;
  $message: any;

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.eventObjectDataSource.model?.caption,
        to: `/manage/events/${this.$route.params.eventId}`,
        exact: true,
      },
      {
        text: "Расписание",
        disabled: true,
      },
    ];
  }

  async created() {
    await this.eventObjectDataSource.get();

    this.loaded = true;
  }

  async setDefault(id: number) {
    await this.eventObjectDataSource.updateField({
      fieldName: "defaultScheduleId",
      fieldValue: id,
    });
    this.$message("Поле успешно обновлено");
  }

  async add() {
    const model: any = await this.editor.update(this.model);
    if (!model) return;

    await this.dataSource.add(model);
    await this.dataSource.get();
    this.$message("Успешно добавлено");
  }

  routeToPage(id: string) {
    this.$router.push({
      name: "event-schedule-id",
      params: { eventId: this.$route.params.eventId, scheduleId: id },
    });
  }
}
